import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Divider, Header, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import PageHeader from "../../../app/layout/PageHeader";

export default observer(function SubscriberReport() {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const { subscriberReportStore } = useStore();
  const { loadSubscriberReport, getSubscriberReport } = subscriberReportStore;

  useEffect(() => {
    loadSubscriberReport();
  }, [loadSubscriberReport]);
  return (
    <>
      <PageHeader
        type="h1"
        header={t("Subscriber Report", { ns: "systemAdmin" })}
        className="modal-text-color"
        textAlign="left"
        divider
        addTitle
      />
      <Header
        as="h3"
        content={`Total Subscribers:`}
        className="modal-text-color"
        textAlign="left"
      />
      <Table singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Plan Name</Table.HeaderCell>
            <Table.HeaderCell>Count</Table.HeaderCell>
            <Table.HeaderCell>% of All</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>X</Table.Cell>
            <Table.Cell>X</Table.Cell>
            <Table.Cell>X</Table.Cell>
          </Table.Row>
        </Table.Body>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>V1 Subscribers</Table.HeaderCell>
            <Table.HeaderCell># Here</Table.HeaderCell>
            <Table.HeaderCell>% Value here</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>X</Table.Cell>
            <Table.Cell>X</Table.Cell>
            <Table.Cell>X</Table.Cell>
          </Table.Row>
        </Table.Body>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>V2 Subscribers</Table.HeaderCell>
            <Table.HeaderCell># here</Table.HeaderCell>
            <Table.HeaderCell>% Value here</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>X</Table.Cell>
            <Table.Cell>X</Table.Cell>
            <Table.Cell>X</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
});
