import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Table } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { formatCurrency } from "../../../app/common/util/functions";
import { Link } from "react-router-dom";
import PageHeader from "../../../app/layout/PageHeader";

export default observer(function RecurringCharges() {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const { recurringChargesStore } = useStore();
  const { loadRecurringChargeReport, getRecurringChargesReport } =
    recurringChargesStore;

  const [filterDate, setFilterDate] = useState<Date | Date[] | undefined>(
    undefined
  );

  useEffect(() => {
    loadRecurringChargeReport();
  }, [loadRecurringChargeReport]);

  return (
    <>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <PageHeader
              type="h1"
              header={"Recurring Charges"}
              className="modal-text-color"
              textAlign="left"
              divider={false}
              addTitle
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <label>{"Date:"}</label>
            <SemanticDatepicker
              name="Date"
              placeholder="Date"
              onChange={(e, d) => {
                setFilterDate(d.value!);
              }}
            />
          </Grid.Column>
          <Grid.Column textAlign="right" className="terms_bold">
            <label>Grand Total: </label>
            Grand Total will go here
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Table basic="very" striped unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>First Name</Table.HeaderCell>
                <Table.HeaderCell>Last Name</Table.HeaderCell>
                <Table.HeaderCell>Company Name</Table.HeaderCell>
                <Table.HeaderCell>Company ID</Table.HeaderCell>
                <Table.HeaderCell>Amount</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {getRecurringChargesReport &&
              getRecurringChargesReport.length > 0 && (
                <Table.Body>
                  {getRecurringChargesReport.map((info, index) => (
                    <Table.Row key={`recurringRow.${info.id}`}>
                      <Table.Cell>{index + 1}</Table.Cell>
                      <Table.Cell>{info.firstName}</Table.Cell>
                      <Table.Cell>{info.lastName}</Table.Cell>
                      <Table.Cell>
                        <Link
                          to={`/admin/company/${info.id}`}
                        >
                          {info.clientName}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>{info.id}</Table.Cell>
                      <Table.Cell>{formatCurrency(info.amount)}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              )}
          </Table>
        </Grid.Row>
      </Grid>
    </>
  );
});
