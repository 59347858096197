import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../../api/agent";
import { Pagination, PagingParams } from "../../../models/pagination";
import { MultipleRecurring, SubscriptionGap } from "../../../models/systemAdmin";
import { sortingStrings } from "../../../common/util/functions";

export default class SystemAdminReportStore {
  constructor() {
    makeAutoObservable(this);
  }

  loadingInitial = false;

  predicate = new Map().set("all", true);
  pagination: Pagination | null = null;
  pagingParams = new PagingParams(1, 25);

  resetAllPredicates = () => {
    this.predicate.clear();
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  resetPredicate = (keyToDelete: string) => {
    this.predicate.delete(keyToDelete);
  };

  setPredicate = (
    predicate: string,
    value:
      | string
      | number
      | boolean
      | Date
      | (string | number | boolean | Date)[]
      | undefined
  ) => {
    this.predicate.clear();
    if (value) this.predicate.set(predicate, value);
  };

  clearPredicate = () => {
    this.predicate.clear();
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = new PagingParams(pagingParams.pageNumber, 25);
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate" || key === "endDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  SubscriptionGapRegistry = new Map<string, SubscriptionGap>();

  loadSubscriptionGap = async () => {
    try {
      this.SubscriptionGapRegistry.clear();
      const result = await agent.SystemAdminReports.getSubscriptionGap(
        this.axiosParams
      );
      runInAction(() => {
        result.data.forEach((x) => {
          this.setSubscriptionGap(x);
        });
        this.setPagination(result.pagination);
      });
    } catch (error) {
      console.log(error);
    }
  };

  private setSubscriptionGap = (subscriptionGap: SubscriptionGap) => {
    subscriptionGap.gapStart = new Date(subscriptionGap.gapStart);
    subscriptionGap.gapEnd = new Date(subscriptionGap.gapEnd);
    this.SubscriptionGapRegistry.set(subscriptionGap.clientId, subscriptionGap);
  };

  get getSubscriptionGap() {
    return Array.from(this.SubscriptionGapRegistry.values()).sort(
      (a, b) => b.gapStart.getTime() - a.gapStart.getTime()
    );
  }

  MultipleRecurringRegistry = new Map<string, MultipleRecurring>();

  loadMultipleRecurring = async () => {
    try {
      this.MultipleRecurringRegistry.clear();
      const result = await agent.SystemAdminReports.getMultipleRecurring();
      runInAction(() => {
        result.forEach((multipleRecurring) => {
          this.setMultipleRecurring(multipleRecurring);
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  private setMultipleRecurring = (multipleRecurring: MultipleRecurring) => {
    this.MultipleRecurringRegistry.set(
      multipleRecurring.clientId,
      multipleRecurring
    );
  };

  get getMultipleRecurring() {
    return Array.from(this.MultipleRecurringRegistry.values()).sort((a, b) => {
      return sortingStrings(a.clientName, b.clientName) ?? 0;
    });
  }
}
