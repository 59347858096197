import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Icon,
  Label,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Formik, FormikErrors } from "formik";
import * as Yup from "yup";
import MyIconTextInput from "../../../app/common/form/MyIconTextInput";
import { TicketItem, TicketItemFormValues } from "../../../app/models/ticket";
import { useStore } from "../../../app/stores/store";
import PageHeader from "../../../app/layout/PageHeader";
import { observer } from "mobx-react-lite";
import { TicketItemType } from "../../../app/models/enums";
import {
  formatDecimal,
  getNegative,
  round2Decimals,
  round3Decimals,
} from "../../../app/common/util/functions";
import MyTextInput from "../../../app/common/form/MyTextInput";
import PartSearchResult from "./PartSearchResult";
import MyIconDecimalInput from "../../../app/common/form/MyIconDecimalInput";
import MyDropdownInput from "../../../app/common/form/MyDropdownInput";
import LoadingComponent from "../../../app/layout/LoadingComponent";

interface Props {
  isAdd: boolean;
  part?: TicketItemFormValues;
  addItem: (values: TicketItemFormValues, type: TicketItemType) => void;
  autoCapitalizeFirstLetter: boolean;
}

export default observer(function PartItemForm({
  isAdd,
  part,
  addItem,
  autoCapitalizeFirstLetter,
}: Props) {
  const { t } = useTranslation(["common", "translation", "tickets"]);

  const { modalStore, vendorStore, partsStore } = useStore();
  const {
    loadDropdownListVendors,
    vendorDropdownList,
    selectedSettingsVendor,
    loadVendors,
    getVendors,
    loadingInitial
  } = vendorStore;

  const { resetSearchPartRegistry } = partsStore;

  useEffect(() => {
    loadDropdownListVendors();
  }, [loadDropdownListVendors]);

  useEffect(() => {
    loadVendors();
  }, [loadVendors]);

  const [partItem, setPartItem] = useState<TicketItemFormValues>(
    new TicketItemFormValues(part)
  );
  const [profit, setProfit] = useState<number>(0);


  const validationSchema = Yup.object({});

  function handleFormSubmit(values: TicketItem) {
    addItem(values, TicketItemType.Parts);
    modalStore.closeModal();
  }

  function calculateFieldsFromVendor(
    vendorId: string,
    quantity: number,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<TicketItem>>,
    partMarkup?: number,
    unitPrice?: number,
    rate?: number
  ) {
    if (vendorId) {
      let markup = calculateMarkupFromVendor(
        vendorId,
        setFieldValue,
        partMarkup
      );

      if (quantity && (unitPrice || rate) && markup) {
        let myRate: number = rate ?? 0;
        let myUnitPrice: number = unitPrice ?? 0;
        if (unitPrice) {
          myRate = calculateRateWithUnitPrice(
            myUnitPrice,
            markup,
            setFieldValue
          );
          calculateSubTotal(quantity, myRate, setFieldValue);
        } else if (rate) {
          myUnitPrice = calculateUnitPrice(rate, markup, setFieldValue);
          calculateSubTotal(quantity, myRate, setFieldValue);
        }
        calculateProfit(myRate, myUnitPrice, quantity);
      }
    }
  }

  function calculateMarkupFromVendor(
    vendorId: string,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<TicketItem>>,
    partMarkup?: number
  ) {
    let vendor = getVendors.find((x) => {
      return x.id === vendorId;
    });

    let markup: number = partMarkup ?? 0.0;
    if (vendor?.defaultMarkup) {
      markup = round2Decimals(vendor?.defaultMarkup);
      setFieldValue("partMarkup", round2Decimals(markup));
    }
    return markup;
  }

  function calculateMarkup(
    unitPrice: number,
    rate: number,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<TicketItem>>
  ) {
    let markup = (rate - unitPrice) * 100;
    setFieldValue("partMarkup", round2Decimals(markup));
    return markup;
  }

  function calculateRateWithUnitPrice(
    unitPrice: number,
    markup: number,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<TicketItem>>
  ) {
    let rate = unitPrice * (1 + markup * 0.01);

    setFieldValue("rate", round3Decimals(rate));
    return rate;
  }

  function calculateRateWithSubTotal(
    quantity: number,
    subTotal: number,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<TicketItem>>
  ) {
    let rate: number = 0.0;
    if (subTotal && quantity) {
      rate = Number(subTotal) / Number(quantity);
      setFieldValue("rate", round3Decimals(rate));
    }
    return rate;
  }

  function calculateUnitPrice(
    rate: number,
    markup: number,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<TicketItem>>
  ) {
    let unitPrice = rate / (1 + markup * 0.01);

    setFieldValue("unitPrice", round2Decimals(unitPrice));
    return unitPrice;
  }

  function calculateSubTotal(
    quantity: number,
    rate: number,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<TicketItem>>
  ) {
    let subtotal = Number(quantity) * Number(rate);
    setFieldValue("subTotal", round2Decimals(subtotal));
    return subtotal;
  }

  function calculateProfit(rate: number, unitPrice: number, quantity: number) {
    let profit = (Number(rate) - Number(unitPrice)) * Number(quantity);
    setProfit(round2Decimals(profit));
    return profit;
  }

  function search(
    values: TicketItem,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<TicketItem>>,
    searchPartCode?: string,
    searchPartName?: string
  ) {
    resetSearchPartRegistry();
    modalStore.openModal(
      <PartSearchResult
        partCode={searchPartCode}
        description={searchPartName}
        addItem={(data) => {
          values.partId = data.id;
          values.partCode = data.partCode;
          values.description = data.name;
          values.quantity = data.quantity ? round2Decimals(data.quantity ?? 1) : 1;
          values.rate = round3Decimals(data.unitPrice ?? 0.0);

          values.unitPrice = round2Decimals( calculateUnitPrice(
            data.unitPrice ?? 0,
            values.partMarkup ?? 0,
            setFieldValue
          ));

          values.subTotal = round2Decimals(calculateSubTotal(
            data.quantity ?? 1,
            values.rate,
            setFieldValue
          ));
          calculateProfit(values.rate, data.unitPrice ?? 0.0, values.quantity);

          modalStore.openModal(
            <PartItemForm
              addItem={addItem}
              isAdd={isAdd}
              part={values}
              autoCapitalizeFirstLetter={autoCapitalizeFirstLetter}
            />
          );
        }}
        cancelSearch={() => {
          modalStore.openModal(
            <PartItemForm
              addItem={addItem}
              isAdd={isAdd}
              part={values}
              autoCapitalizeFirstLetter={autoCapitalizeFirstLetter}
            />
          );
        }}
      />
    );
  }

  if (loadingInitial)
    return <LoadingComponent content={t("loading", { ns: "common" })} />;

  return (
    <>
      <PageHeader
        type={"h3"}
        header={t("pops.add_prt_itm", {
          ns: "tickets",
        })}
        divider={true}
        addTitle={false}
      />
      <Formik
        validationSchema={validationSchema}
        initialValues={partItem}
        onSubmit={(values: TicketItem) => handleFormSubmit(values)}
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleBlur,
          handleChange,
          errors,
          values,
          touched,
          setFieldValue,
        }) => (
          <Form className="ui form error" autoComplete="on" onSubmit={handleSubmit}>
            <Grid stackable>
              <Grid.Row columns={2}>
                <Grid.Column verticalAlign="bottom">
                  <Container className="search-input">
                    <MyTextInput
                      placeholder={t("estimate.part_no", { ns: "tickets" })}
                      label={t("estimate.part_no", { ns: "tickets" }) + ":"}
                      className="full-width"
                      name="partCode"
                      autoCapitalizeFirstLetter={autoCapitalizeFirstLetter}
                      maxLength={50}
                      autoFocus={true}
                    />
                    <Button
                      icon="search"
                      attached="right"
                      type="button"
                      className="searchButton"
                      onClick={() => {
                        if (values.partCode)
                          search(values, setFieldValue, values.partCode);
                      }}
                    />
                  </Container>
                </Grid.Column>
                <Grid.Column>
                  <Container className="search-input">
                    <MyTextInput
                      placeholder={t("pops.part_desc", { ns: "tickets" })}
                      label={t("pops.part_desc", { ns: "tickets" }) + ":"}
                      className="full-width"
                      name="description"
                      autoCapitalizeFirstLetter={autoCapitalizeFirstLetter}
                      maxLength={1000}
                    />
                    <Button
                      icon="search"
                      attached="right"
                      type="button"
                      className="searchButton"
                      onClick={() => {
                        if (values.description)
                          search(
                            values,
                            setFieldValue,
                            undefined,
                            values.description
                          );
                      }}
                    />
                  </Container>
                </Grid.Column>
              </Grid.Row>
              {selectedSettingsVendor.enablePartMarkup && (
                <>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <MyDropdownInput
                        options={vendorDropdownList}
                        label={t("pops.vendor", { ns: "tickets" })}
                        placeholder={t("pops.vendor", { ns: "tickets" })}
                        name="vendorId"
                        onChangeFunction={(d) => {
                          if (d) {
                            calculateFieldsFromVendor(
                              d,
                              values.quantity,
                              setFieldValue,
                              values.partMarkup,
                              values.unitPrice,
                              values.rate
                            );
                          }
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <MyIconTextInput
                        icon="percent"
                        placeholder="0.00"
                        label={t("pops.mark_up", { ns: "tickets" }) + ":"}
                        name="partMarkup"
                        onChange={(d) => {
                          if ((d && values.quantity, values.unitPrice)) {
                            let rate = calculateRateWithUnitPrice(
                              values.unitPrice,
                              d,
                              setFieldValue
                            );
                            calculateSubTotal(
                              values.quantity,
                              rate,
                              setFieldValue
                            );
                            calculateProfit(
                              rate,
                              values.unitPrice,
                              values.quantity
                            );
                          }
                        }}
                        onBlur={(data) => {
                          if ((data && values.quantity, values.unitPrice)) {
                            let rate = calculateRateWithUnitPrice(
                              values.unitPrice,
                              data,
                              setFieldValue
                            );
                            calculateSubTotal(
                              values.quantity,
                              rate,
                              setFieldValue
                            );
                            calculateProfit(
                              rate,
                              values.unitPrice,
                              values.quantity
                            );
                          }
                        }}
                        maxLength={500}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <MyIconDecimalInput
                        icon="dollar sign"
                        iconPosition="left"
                        placeholder="0.00"
                        label={t("pops.cost", { ns: "tickets" }) + ":"}
                        name="unitPrice"
                        onChange={(d) => {
                          if (d && values.quantity && values.partMarkup) {
                            let rate = calculateRateWithUnitPrice(
                              d,
                              values.partMarkup,
                              setFieldValue
                            );
                            calculateSubTotal(
                              values.quantity,
                              rate,
                              setFieldValue
                            );
                            calculateProfit(rate, d, values.quantity);
                          }
                        }}
                        onBlur={(d) => {
                          //checked
                          if (d && values.quantity && values.partMarkup) {
                            let rate = calculateRateWithUnitPrice(
                              d,
                              values.partMarkup,
                              setFieldValue
                            );
                            calculateSubTotal(
                              values.quantity,
                              rate,
                              setFieldValue
                            );
                            calculateProfit(rate, d, values.quantity);
                          }
                        }}
                        maxLength={500}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <label className={"myLabel"}>
                        {t("pops.profit", { ns: "tickets" })}:
                      </label>
                      <br />
                      <Label
                        basic
                        simple
                        fluid
                        className="myLabel shortInput labelHeight"
                        style={{
                          color: getNegative(profit ?? 0),
                        }}
                      >
                        <Icon name="dollar" />
                        {formatDecimal(round2Decimals(profit ?? 0) ?? 0, 2)}
                      </Label>
                    </Grid.Column>
                  </Grid.Row>
                </>
              )}

              <Grid.Row>
                <Grid.Column>
                  <MyIconDecimalInput
                    icon="boxes"
                    iconPosition="left"
                    placeholder="0.00"
                    label={t("pops.qty", { ns: "tickets" }) + ":"}
                    name="quantity"
                    onChange={(d) => {
                      if (d && values.rate) {
                        calculateSubTotal(d, values.rate, setFieldValue);
                        if (
                          selectedSettingsVendor.enablePartMarkup &&
                          values.unitPrice
                        ) {
                          calculateProfit(values.rate, values.unitPrice, d);
                        }
                      }
                    }}
                    onBlur={(d) => {
                      //checked
                      if (d && values.rate) {
                        calculateSubTotal(d, values.rate, setFieldValue);
                        if (
                          selectedSettingsVendor.enablePartMarkup &&
                          values.unitPrice
                        ) {
                          calculateProfit(values.rate, values.unitPrice, d);
                        }
                      }
                    }}
                    maxLength={500}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <MyIconDecimalInput
                    icon="dollar sign"
                    iconPosition="left"
                    placeholder="0.000"
                    label={t("pops.price_each", { ns: "tickets" }) + ":"}
                    name="rate"
                    onChange={(d) => {
                      if (d && values.quantity) {
                        calculateSubTotal(values.quantity, d, setFieldValue);
                        calculateProfit(
                          d,
                          values.unitPrice ?? 0,
                          values.quantity
                        );
                      }
                    }}
                    onBlur={(d) => {
                      //checked
                      if (d && values.quantity) {
                        calculateSubTotal(values.quantity, d, setFieldValue);
                        calculateProfit(
                          d,
                          values.unitPrice ?? 0,
                          values.quantity
                        );
                      }
                    }}
                    significantDigits={3}
                    maxLength={500}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <MyIconDecimalInput
                    icon="dollar sign"
                    iconPosition="left"
                    placeholder="0.00"
                    label={t("pops.sub_total", { ns: "tickets" }) + ":"}
                    name="subTotal"
                    onChange={(d) => {
                      if (d && values.quantity) {
                        let rate = calculateRateWithSubTotal(
                          values.quantity,
                          d,
                          setFieldValue
                        );
                        if (
                          values.unitPrice &&
                          selectedSettingsVendor.enablePartMarkup
                        ) {
                          calculateProfit(
                            rate,
                            values.unitPrice,
                            values.quantity
                          );
                          calculateMarkup(
                            values.unitPrice,
                            rate,
                            setFieldValue
                          );
                        }
                      }
                    }}
                    onBlur={(d) => {
                      if (d && values.quantity) {
                        let rate = calculateRateWithSubTotal(
                          values.quantity,
                          d,
                          setFieldValue
                        );
                        if (
                          values.unitPrice &&
                          selectedSettingsVendor.enablePartMarkup
                        ) {
                          calculateProfit(
                            rate,
                            values.unitPrice,
                            values.quantity
                          );
                          calculateMarkup(
                            values.unitPrice,
                            rate,
                            setFieldValue
                          );
                        }
                      }
                    }}
                    maxLength={500}
                  />
                </Grid.Column>
              </Grid.Row>
              <Divider />
              <Grid.Row>
                <Grid.Column>
                  <Button color="green" type="submit">
                    <Icon name="check" />
                    {t("pops.add_prt_itm", { ns: "tickets" })}
                  </Button>
                  <Button
                    type="button"
                    onClick={() => {
                      modalStore.closeModal();
                    }}
                  >
                    <Icon name="x" />
                    {t("cancel", { ns: "common" })}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
});
