import { useField } from "formik";
import React from "react";
import { Button, Form, Icon, Input, Label } from "semantic-ui-react";
import MyHelpButton from "./MyHelpButton";

interface Props {
  placeholder: string;
  name: string;
  label?: string;
  className?: string;
  showHelp?: boolean;
  helpName?: string;
  icon: string;
  iconPosition?: "left";
  disabled?: boolean;
  autoFocus?: boolean;
  myValidate?: (value: string) => void;
  helpSeeMore?: boolean;
  showDefaults?: boolean;
  onSetDefaults?: (value: string) => void;
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
  significantDigits?: number;
  maxLength: number;
}

export default function MyIconDecimalInput({
  placeholder,
  name,
  label,
  className,
  showHelp,
  helpName,
  icon,
  iconPosition,
  disabled,
  autoFocus,
  myValidate,
  helpSeeMore,
  showDefaults,
  onSetDefaults,
  onChange,
  onBlur,
  significantDigits,
  maxLength,
}: Readonly<Props>) {
  const [field, meta, helpers] = useField(name);
  let labelClassName: string = "myLabel";
  let inputClassName: string = "input";
  if (className) labelClassName = `${labelClassName} ${className}`;
  if (className) inputClassName = `${inputClassName} ${className}`;
  let digits: number = significantDigits ?? 2;

  return (
    <Form.Field error={meta.touched && !!meta.error} className="ui" name={name}>
      {label && label.length > 0 && (
        <label className={labelClassName} htmlFor={name}>
          {`${label} `}
          {showHelp && (
            <MyHelpButton
              name={`${name}.help`}
              help={helpName ? helpName : ""}
              seeMore={helpSeeMore}
              color={"blue"}
            />
          )}
          {showDefaults && (
            <Button
              size={"mini"}
              basic
              floated="right"
              compact
              type="button"
              color="green"
            >
              <Icon floated="right" color="green" name="copy outline" />
            </Button>
          )}
        </label>
      )}
      <Input
        {...field}
        type="number"
        placeholder={placeholder}
        name={name}
        icon={icon}
        iconPosition={iconPosition}
        disabled={disabled}
        maxLength={maxLength}
        step={0.01}
        autoFocus={autoFocus ?? false}
        onInput={(e: any, d: any) => {
          const value = e.target.value;
          const arr = value.split(".");
          if (arr.length === 2) {
            if (arr[1].length > 2) {
              field.value = arr[0] + "." + arr[1].substring(0, digits);
              e.target.value = field.value;
            }
          }
          if (myValidate) myValidate(e.target.value);
        }}
        onChange={(e, d) => {
          field.onChange(e);
          let val = d.value !== "" ? d.value : undefined;
          helpers.setValue(val);
          if (onChange) onChange(d.value);
        }}
        onBlur={(e: any, d: any) => {
          field.onBlur(e);
          if (onBlur) onBlur(field.value);
        }}
        className={inputClassName}
        value={field.value || undefined}
        id={name}
        label={undefined}
      />
      {meta.touched && meta.error && (
        <Label basic color="red">
          {meta.error}
        </Label>
      )}
    </Form.Field>
  );
}
