import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { Grid, Header, Divider, Button, Table } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { useStore } from "../../../app/stores/store";
import { format } from "date-fns";
import { formatCurrency } from "../../../app/common/util/functions";

const adminOptions = [
  { key: "Arabic", text: "Arabic", value: "Arabic" },
  { key: "Chinese", text: "Chinese", value: "Chinese" },
];

export default observer(function ANSettle() {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const { authorizeNetReportStore } = useStore();
  const {
    loadAuthorizeNetReport,
    calculateTotals,
    getTransactions,
    getVisaMcTransactions,
    getAmexTransactions,
    getDiscoverTransactions,
    getApprovedTransactions,
    getDeclinedTransactions,
    getTransactionsGroupByCardType,
    getTransactionsGroupByStatus,
  } = authorizeNetReportStore;

  const [startDate, setStartDate] = useState<Date>(new Date());
  //  const [enddate, setendDate] = useState<Date | Date[] | undefined>(undefined);

  return (
    <>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <SemanticDatepicker
              name="startDate"
              format="MM/DD/YYYY"
              placeholder={t("start_date", { ns: "reports" })}
              value={startDate}
              onChange={(e, d) => {
                if (d.value && !Array.isArray(d.value)) {
                  let newDate = new Date(
                    d.value.getFullYear(),
                    d.value.getMonth(),
                    d.value.getDate()
                  );
                  setStartDate(newDate);
                }
              }}
            />
            <Button
              color="green"
              className="save_button"
              icon="check"
              content={t("get_report", { ns: "reports" })}
              onClick={() => {
                loadAuthorizeNetReport(startDate);
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header
              as="h3"
              content={"Authorize.Net Daily Settlement"}
              className="modal-text-color"
              textAlign="left"
              divider={false}
              addTitle
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width="8">Date</Table.HeaderCell>
                  <Table.HeaderCell>Transactions</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Charges</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell width="8">{format(startDate, "P")}</Table.Cell>
                  <Table.Cell>{getTransactions.length}</Table.Cell>
                  <Table.Cell textAlign="right">
                    {formatCurrency(
                      calculateTotals(getApprovedTransactions).amount
                    )}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header
              as="h3"
              content={"Transaction Types"}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width="8">
                    Transaction Type
                  </Table.HeaderCell>
                  <Table.HeaderCell>Amount</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Count</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell width="8" className="intro">
                    Charge
                  </Table.Cell>
                  <Table.Cell>
                    {formatCurrency(
                      calculateTotals(getApprovedTransactions).amount
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {getApprovedTransactions.length}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width="8" className="intro">
                    Declined
                  </Table.Cell>
                  <Table.Cell>
                    {formatCurrency(
                      calculateTotals(getDeclinedTransactions).amount
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {getDeclinedTransactions.length}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header
              as="h3"
              content={"Card Transactions"}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width="8">Card Type</Table.HeaderCell>
                  <Table.HeaderCell>Amount</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Count</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell width="8" className="intro">
                    Visa/MC
                  </Table.Cell>
                  <Table.Cell>
                    {formatCurrency(
                      calculateTotals(getVisaMcTransactions).amount
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="right">{getVisaMcTransactions.length}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width="8" className="intro">
                    American Express
                  </Table.Cell>
                  <Table.Cell>
                    {formatCurrency(
                      calculateTotals(getAmexTransactions).amount
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="right">{getAmexTransactions.length}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width="8" className="intro">
                    Discover
                  </Table.Cell>
                  <Table.Cell>
                    {formatCurrency(
                      calculateTotals(getDiscoverTransactions).amount
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="right">{getDiscoverTransactions.length}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header
              as="h3"
              content={"Plan Type Breakdown"}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width="8">Plan Type</Table.HeaderCell>
                  <Table.HeaderCell>Count</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Amount</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell width="8" className="intro">
                    Basic- Monthly
                  </Table.Cell>
                  <Table.Cell>X</Table.Cell>
                  <Table.Cell textAlign="right">X</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width="8" className="intro">
                    Basic- Quarterly
                  </Table.Cell>
                  <Table.Cell>X</Table.Cell>
                  <Table.Cell textAlign="right">X</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width="8" className="intro">
                    Basic- Yearly
                  </Table.Cell>
                  <Table.Cell>X</Table.Cell>
                  <Table.Cell textAlign="right">X</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width="8" className="intro">
                    Pro- Monthly
                  </Table.Cell>
                  <Table.Cell>X</Table.Cell>
                  <Table.Cell textAlign="right">X</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width="8" className="intro">
                    Pro- Quarterly
                  </Table.Cell>
                  <Table.Cell>X</Table.Cell>
                  <Table.Cell textAlign="right">X</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width="8" className="intro">
                    Pro- Yearly
                  </Table.Cell>
                  <Table.Cell>X</Table.Cell>
                  <Table.Cell textAlign="right">X</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header
              as="h3"
              content={"Non-plan Transactions"}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width="8">Type</Table.HeaderCell>
                  <Table.HeaderCell>Count</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Amount</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell width="8" className="intro">
                    Upgrade to Pro
                  </Table.Cell>
                  <Table.Cell>X</Table.Cell>
                  <Table.Cell textAlign="right">X</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header
              as="h3"
              content={"Service Type Breakdown"}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Service Type</Table.HeaderCell>
                  <Table.HeaderCell>Sub Count</Table.HeaderCell>
                  <Table.HeaderCell>Sub Amount</Table.HeaderCell>
                  <Table.HeaderCell>Upgrade Count</Table.HeaderCell>
                  <Table.HeaderCell>Upgrade Amount</Table.HeaderCell>
                  <Table.HeaderCell>Add Users Count</Table.HeaderCell>
                  <Table.HeaderCell>Add Users Amount</Table.HeaderCell>
                  <Table.HeaderCell>Total Amount</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell className="intro">Basic</Table.Cell>
                  <Table.Cell textAlign="right">X</Table.Cell>
                  <Table.Cell textAlign="right">X</Table.Cell>
                  <Table.Cell textAlign="right">X</Table.Cell>
                  <Table.Cell textAlign="right">X</Table.Cell>
                  <Table.Cell textAlign="right">X</Table.Cell>
                  <Table.Cell textAlign="right">X</Table.Cell>
                  <Table.Cell textAlign="right">X</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="intro">Pro</Table.Cell>
                  <Table.Cell textAlign="right">X</Table.Cell>
                  <Table.Cell textAlign="right">X</Table.Cell>
                  <Table.Cell textAlign="right">X</Table.Cell>
                  <Table.Cell textAlign="right">X</Table.Cell>
                  <Table.Cell textAlign="right">X</Table.Cell>
                  <Table.Cell textAlign="right">X</Table.Cell>
                  <Table.Cell textAlign="right">X</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header
              as="h3"
              content={"Transaction New/Recurring Grouped"}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width="8">Bucket</Table.HeaderCell>
                  <Table.HeaderCell>Amount</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Count</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell width="8">X</Table.Cell>
                  <Table.Cell>X</Table.Cell>
                  <Table.Cell textAlign="right">X</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <Divider />
      </Grid>
    </>
  );
});
