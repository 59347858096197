import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { Grid, Icon, Table, Menu } from "semantic-ui-react";
import PageHeader from "../../../app/layout/PageHeader";

export default observer(function NoLogins() {
  const { t } = useTranslation(["common", "systemAdmin"]);

  return (
    <>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <PageHeader
              type="h1"
              header={t("reports.noLogins_intro", {
                ns: "systemAdmin",
              }).toString()}
              className="modal-text-color"
              textAlign="left"
              divider
              addTitle
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid stackable>
        <Grid.Column stackable>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {t("reports.comp_id", { ns: "systemAdmin" })}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("reports.created", { ns: "systemAdmin" })}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>Company ID here</Table.Cell>
                <Table.Cell>date here</Table.Cell>
              </Table.Row>
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="5">
                  <Menu floated="right" pagination>
                    <Menu.Item as="a" icon>
                      <Icon name="chevron left" />
                    </Menu.Item>
                    <Menu.Item as="a">1</Menu.Item>
                    <Menu.Item as="a">2</Menu.Item>
                    <Menu.Item as="a">3</Menu.Item>
                    <Menu.Item as="a">4</Menu.Item>
                    <Menu.Item as="a" icon>
                      <Icon name="chevron right" />
                    </Menu.Item>
                  </Menu>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Grid.Column>
      </Grid>
    </>
  );
});
