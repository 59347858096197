import { observer } from "mobx-react-lite";
import { Icon, Message, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import MyPaging from "../../../app/common/form/MyPaging";
import { PagingParams } from "../../../app/models/pagination";

interface Props{
  clientId: string;
}

export default observer(function ClientSubscriptionHistory({clientId}: Props) {
  const { t } = useTranslation(["common"]);
  const { clientSystemAdminStore } = useStore();

  const {
    loadClientSubscriptionHistory,
    getClientSubscriptionHistory,
    pagination,
    setPagingParams,
    pagingParams,
  } = clientSystemAdminStore;

  useEffect(() => {
    loadClientSubscriptionHistory(clientId);
  }, [loadClientSubscriptionHistory,clientId ]);

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      loadClientSubscriptionHistory(clientId);
    }
  }

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>RecurringId</Table.HeaderCell>
          <Table.HeaderCell>Plan Name</Table.HeaderCell>
          <Table.HeaderCell>Amount</Table.HeaderCell>
          <Table.HeaderCell>Start Date</Table.HeaderCell>
          <Table.HeaderCell>Next Charge</Table.HeaderCell>
          <Table.HeaderCell>End Date</Table.HeaderCell>
          <Table.HeaderCell>Cancel Date</Table.HeaderCell>
          <Table.HeaderCell>Last Four</Table.HeaderCell>
          <Table.HeaderCell>Client Name</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {getClientSubscriptionHistory &&
        getClientSubscriptionHistory.length > 0 ? (
          getClientSubscriptionHistory.map((x) => (
            <Table.Row key={`client.${x.id}`}>
              <Table.Cell>{x.id}</Table.Cell>
              <Table.Cell>{x.plan}</Table.Cell>
              <Table.Cell>{x.amount}</Table.Cell>
              <Table.Cell>{format(x.startDate, "P")}</Table.Cell>
              <Table.Cell>
                {x.nextCharge && format(x.nextCharge, "P")}
              </Table.Cell>
              <Table.Cell>{format(x.endDate, "P")}</Table.Cell>
              <Table.Cell>
                {x.cancelDate && format(x.cancelDate, "P")}
              </Table.Cell>
              <Table.Cell>{x.lastFour}</Table.Cell>
              <Table.Cell>{x.cardholderName}</Table.Cell>
              <Table.Cell>
                {x.status ? (
                  <Icon name="check" color="green" />
                ) : (
                  <Icon name="x" color="red" />
                )}
              </Table.Cell>
            </Table.Row>
          ))
        ) : (
          <Table.Row key={"noItems"}>
            <Table.Cell width="9" colSpan={9}>
              <Message
                color="yellow"
                className="textAlignCenter fontStyleItalic"
              >
                {t("grid_noItems", { ns: "common" })}
              </Message>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="9">
            <MyPaging
              itemCount={pagination ? pagination.totalItems : 10}
              pageSize={pagingParams.pageSize}
              currentPage={pagination ? pagination.currentPage : 1}
              onClick={listOnClick}
              onChange={listOnClick}
              floated="right"
            ></MyPaging>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
});
