import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import {
  Grid,
  Header,
  Divider,
  Table,
  Button,
  Message,
} from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { useStore } from "../../../app/stores/store";
import MyPaging from "../../../app/common/form/MyPaging";
import { PagingParams } from "../../../app/models/pagination";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import PageHeader from "../../../app/layout/PageHeader";

export default observer(function SubEnded() {
  const { t } = useTranslation(["common", "systemAdmin"]);

  const { systemAdminReportStore } = useStore();

  const {
    loadSubscriptionGap,
    getSubscriptionGap,
    SubscriptionGapRegistry,
    pagination,
    setPagingParams,
    pagingParams,
  } = systemAdminReportStore;

  const [startdate, setStartDate] = useState<Date | Date[] | undefined>(
    undefined
  );

  const [enddate, setEndDate] = useState<Date | Date[] | undefined>(undefined);

  useEffect(() => {
      loadSubscriptionGap();
  }, [loadSubscriptionGap]);

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      SubscriptionGapRegistry.clear();
    }
  }

  return (
    <>
      <Grid stackable>
        <Grid.Row></Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <label>{t("start_date", { ns: "reports" }) + ":"}</label>{" "}
            <SemanticDatepicker
              name="startDate"
              placeholder={t("start_date", { ns: "reports" })}
              onChange={(e, d) => {
                setStartDate(d.value ? d.value : undefined);
              }}
            />{" "}
          </Grid.Column>
          <Grid.Column>
            <label>{t("end_date", { ns: "reports" }) + ":"}</label>{" "}
            <SemanticDatepicker
              name="endDate"
              placeholder={t("end_date", { ns: "reports" })}
              onChange={(e, d) => {
                setEndDate(d.value ? d.value : undefined);
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <Button
              color="green"
              className="save_button"
              icon="check"
              content={t("get_report", { ns: "reports" })}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <PageHeader
              type="h2"
              header={t("reports.overview", {
                ns: "systemAdmin",
              }).toString()}
              className="modal-text-color"
              textAlign="left"
              divider
              addTitle
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Table striped>
              <Table.Body>
                <Table.Row>
                  <Table.Cell className="terms_bold" textAlign="right">
                    Company Status
                  </Table.Cell>
                  <Table.Cell className="terms_bold" textAlign="right">
                    Companies
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="terms_bold" textAlign="right">
                    {}: Subscription Ended
                  </Table.Cell>
                  <Table.Cell textAlign="right">x</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="terms_bold" textAlign="right">
                    {}: Subscription Active
                  </Table.Cell>
                  <Table.Cell textAlign="right">x</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="terms_bold" textAlign="right">
                    {}: Subscription Extended
                  </Table.Cell>
                  <Table.Cell textAlign="right">x</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header
              as="h2"
              content={t("total", {
                ns: "common",
              }).toString()}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row columns={1}>
          <Grid.Column>
            <Table striped>
              <Table.Body>
                <Table.Row>
                  <Table.Cell className="terms_bold" textAlign="right">
                    Companies
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign="right">x</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column stackable>
            <Header
              as="h2"
              content={t("reports.report", {
                ns: "systemAdmin",
              }).toString()}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row columns={1}>
          <Grid.Column>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    {t("reports.comp_id", { ns: "systemAdmin" })}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("date", { ns: "common" })}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("reports.company_status", { ns: "systemAdmin" })}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("reports.re-subbed", { ns: "systemAdmin" })}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("days", { ns: "common" })}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {getSubscriptionGap && getSubscriptionGap.length > 0 ? (
                  getSubscriptionGap.map((x) => (
                    <Table.Row key={`subGap.${x.clientId}`}>
                      <Table.Cell><Link className="tableLink" to={`/admin/company/${x.clientId}`}>{x.clientId}</Link></Table.Cell>
                      <Table.Cell>{format(x.gapStart, "P p")}</Table.Cell>
                      <Table.Cell>{x.clientStatus}</Table.Cell>
                      <Table.Cell>{format(x.gapEnd, "P p")}</Table.Cell>
                      <Table.Cell>{x.gapLength}</Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row key={"noitems"}>
                    <Table.Cell width="8" colSpan={8}>
                      <Message
                        color="yellow"
                        className="textAlignCenter fontStyleItalic"
                      >
                        {t("grid_noItems", { ns: "common" })}
                      </Message>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="6">
                    <MyPaging
                      itemCount={pagination ? pagination.totalItems : 25}
                      pageSize={pagingParams.pageSize}
                      currentPage={pagination ? pagination.currentPage : 1}
                      onClick={listOnClick}
                      onChange={listOnClick}
                      floated="right"
                    ></MyPaging>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
});
